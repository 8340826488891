import { Button, Flex, Heading } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

// import DutchieSync from 'components/segments/DutchieSync';
// import useLocations from 'hooks/useLocations';
import List from './List';

const Locations = () => {
  // const { getLocationList } = useLocations();
  const navigate = useNavigate();

  return (
    <Flex flexDirection='column'>
      <Flex justifyContent='space-between'>
        <Heading>Plantlife Locations</Heading>
        <Flex alignItems='flex-end' gap='10px'>
          <Button onClick={() => navigate('/locations/add')}>+ Add New Location</Button>
        </Flex>
      </Flex>
      <List />
    </Flex>
  );
};

export default Locations;
