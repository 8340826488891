export const LOCATION_ADD_REQUEST = 'LOCATION_ADD_REQUEST';
export const LOCATION_ADD_RESPONSE = 'LOCATION_ADD_RESPONSE';
export const LOCATION_ADD_ERROR = 'LOCATION_ADD_ERROR';
export const LOCATIONS_LIST_REQUEST = 'LOCATIONS_LIST_REQUEST';
export const LOCATIONS_LIST_RESPONSE = 'LOCATIONS_LIST_RESPONSE';
export const LOCATIONS_LIST_ERROR = 'LOCATIONS_LIST_ERROR';
export const LOCATIONS_LIST_RESET = 'LOCATIONS_LIST_RESET';
export const LOCATION_GET_REQUEST = 'LOCATION_GET_REQUEST';
export const LOCATION_GET_RESPONSE = 'LOCATION_GET_RESPONSE';
export const LOCATION_GET_ERROR = 'LOCATION_GET_ERROR';
export const LOCATION_UPDATE_REQUEST = 'LOCATION_UPDATE_REQUEST';
export const LOCATION_UPDATE_RESPONSE = 'LOCATION_UPDATE_RESPONSE';
export const LOCATION_UPDATE_ERROR = 'LOCATION_UPDATE_ERROR';
export const LOCATION_DELETE_REQUEST = 'LOCATION_DELETE_REQUEST';
export const LOCATION_DELETE_RESPONSE = 'LOCATION_DELETE_RESPONSE';
export const LOCATION_DELETE_ERROR = 'LOCATION_DELETE_ERROR';

const initialState = {
  isUpdating: false,
  isRequesting: false,
  lastListResponse: null,
  lastListError: null,
  lastGetResponse: null,
  lastGetError: null,
  lastUpdateResponse: null,
  lastUpdateError: null,
  page: 1,
  pages: 0,
  count: 0,
  list: []
};

const locations = (state = initialState, action) => {
  let list = [...state.list];
  switch (action?.type) {
    case LOCATIONS_LIST_REQUEST:
      return {
        ...state,
        isRequesting: true,
        lastListResponse: null,
        lastListError: null
      };
    case LOCATIONS_LIST_RESPONSE:
      list = action?.data;
      return {
        ...state,
        list,
        page: action?.page || 1,
        pages: action?.pages || 0,
        count: action?.count || 0,
        isRequesting: false,
        lastListResponse: action?.response,
        lastListError: null
      };
    case LOCATIONS_LIST_ERROR:
      return {
        ...state,
        list: [],
        page: 1,
        pages: 0,
        count: 0,
        isRequesting: false,
        lastListResponse: null,
        lastListError: action?.data
      };
    case LOCATIONS_LIST_RESET:
      return {
        ...state,
        isRequesting: false,
        lastListResponse: null,
        lastListError: null,
        list: [],
        page: 1,
        pages: 0,
        count: 0
      };
    case LOCATION_GET_REQUEST:
      return {
        ...state,
        isRequesting: true,
        lastGetResponse: null,
        lastGetError: null
      };
    case LOCATION_GET_RESPONSE:
      const location = action?.data;
      const existsIndex = list.findIndex(loc => loc._id === location.id);
      if (existsIndex > -1) {
        list[existsIndex] = location;
      } else {
        list.push(location);
      }
      return {
        ...state,
        list,
        isRequesting: false,
        lastGetResponse: location,
        lastGetError: null
      };
    case LOCATION_GET_ERROR:
      return {
        ...state,
        isRequesting: false,
        lastGetResponse: null,
        lastGetError: action?.data
      };

    case LOCATION_ADD_REQUEST:
    case LOCATION_UPDATE_REQUEST:
      // case LOCATION_DELETE_REQUEST:
      return {
        ...state,
        isUpdating: true,
        lastUpdateResponse: null,
        lastUpdateError: null
      };

    case LOCATION_ADD_RESPONSE:
      const addedLocation = action?.data;
      if (addedLocation) {
        list.push(addedLocation);
      }
      return {
        ...state,
        list,
        isUpdating: false,
        lastUpdateResponse: action?.response,
        lastUpdateError: null
      };

    case LOCATION_UPDATE_RESPONSE:
      const updatedLocation = action?.data;
      if (updatedLocation) {
        list = list.map(location => {
          if (location._id === updatedLocation._id) {
            return updatedLocation;
          }
          return location;
        });
      }
      return {
        ...state,
        list,
        isUpdating: false,
        lastUpdateResponse: action?.response,
        lastUpdateError: null
      };
    // case LOCATION_DELETE_RESPONSE:
    //   const deletedLocation = action?.data;
    //   if (deletedLocation) {
    //     list = list.filter(location => location._id !== deletedLocation);
    //   } else {
    //     console.error('delete response received, but no location in action.data??', action);
    //   }
    //   return {
    //     ...state,
    //     list,
    //     isUpdating: false,
    //     lastUpdateResponse: action?.response,
    //     lastUpdateError: null
    //   };
    case LOCATION_ADD_ERROR:
    case LOCATION_UPDATE_ERROR:
      // case LOCATION_DELETE_ERROR:
      return {
        ...state,
        isUpdating: false,
        lastUpdateResponse: null,
        lastUpdateError: action?.data
      };
    default:
      return state;
  }
};

export default locations;
