import { useEffect, useRef } from 'react';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Link,
  Table,
  Tbody,
  Tr,
  Td,
  // Thead,
  ButtonGroup,
  Spinner
} from '@chakra-ui/react';

import useEditor from 'hooks/useEditor';
import useLocations from 'hooks/useLocations';
import SaveCancelDialogue from 'components/segments/CancelDialogue';
import SetLocationHours from './SetLocationHours';
import RichContentBlock from 'components/segments/RichContentBlock';
import { PLC_MID_GREEN } from 'constants/styles';
import SetHours from './SetHours';
import FileUploadSingleImage from 'components/segments/FileUploadSingleImage';

const getInitialData = () => {
  return {
    useDefaultHours: true
  };
};

const Editor = () => {
  const { id } = useParams();
  const {
    locations,
    isRequesting,
    isUpdating,
    lastGetError,
    lastUpdateError,
    getLocation,
    saveLocation
  } = useLocations();
  const navigate = useNavigate();
  const hasSetData = useRef();
  const isGettingData = useRef();

  const {
    data,
    changesDetected,
    handleUpdateEditorData,
    handleUpdateEditorDataDirect,
    handleRichContentBlockChange,
    handleFileUpload,
    handleCheckboxClick,
    setFormData,
    handleSave,
    handleStartCancel
  } = useEditor({
    initialData: getInitialData(),
    isUpdating,
    lastUpdateError,
    saveFunc: data => saveLocation({ data, location: id }),
    saveLabel: 'Location',
    isEditing: !!id
  });

  useEffect(() => {
    if (id && !hasSetData.current && !isRequesting) {
      const editLocation = locations?.find(l => l._id === id);
      if (editLocation) {
        hasSetData.current = true;
        isGettingData.current = false;
        console.log('setting form data to:', editLocation);
        setFormData(editLocation);
      } else {
        if (!lastGetError && !isGettingData.current) {
          isGettingData.current = true;
          getLocation(id);
        }
      }
    }
  }, [id, locations, getLocation, setFormData, isRequesting, lastGetError]);

  const handleSetLocationHours = hrs => {
    if (hrs) {
      handleUpdateEditorDataDirect(hrs);
    }
  };

  if (isRequesting) {
    return (
      <Box>
        <Heading mb='20px' size='lg'>
          Loading, one moment please...
        </Heading>
        <Spinner size='xl' />
      </Box>
    );
  }

  return (
    <Box>
      <Heading mb='20px' size='lg'>
        {id ? `Editing Location: ${data.name || data.dutchieData?.name}` : 'Add a new location'}
      </Heading>

      <Flex gap='20px'>
        <Flex flex='1' gap='20px'>
          <Box flex='0.75' align='flex-start'>
            <FormControl>
              <FormLabel mb='0' fontSize='sm'>
                Name
              </FormLabel>
              <Input
                type='text'
                name='name'
                value={data?.name || ''}
                onChange={handleUpdateEditorData}
                size='sm'
                placeholder='Store Name'
              />
            </FormControl>
            {!data.dutchieData && (
              <FormControl>
                <FormLabel mb='0' fontSize='sm'>
                  Slug{' '}
                  <span style={{ fontSize: '11px' }}>
                    (this needs to match what Breadstack uses in their URL)
                  </span>
                </FormLabel>
                <Input
                  type='text'
                  name='slug'
                  value={data?.slug || ''}
                  onChange={handleUpdateEditorData}
                  size='sm'
                  placeholder='Store Slug'
                />
              </FormControl>
            )}
            {!data.dutchieData && (
              <>
                <FormControl>
                  <FormLabel mb='0' fontSize='sm'>
                    Address
                  </FormLabel>
                  <Input
                    type='text'
                    name='line1'
                    value={data?.line1 || ''}
                    onChange={handleUpdateEditorData}
                    size='sm'
                    placeholder='Address Line'
                  />
                </FormControl>
                <FormControl>
                  <FormLabel mb='0' fontSize='sm'>
                    City
                  </FormLabel>
                  <Input
                    type='text'
                    name='city'
                    value={data?.city || ''}
                    onChange={handleUpdateEditorData}
                    size='sm'
                    placeholder='City'
                  />
                </FormControl>
                <FormControl>
                  <FormLabel mb='0' fontSize='sm'>
                    Province
                  </FormLabel>
                  <Input
                    type='text'
                    name='state'
                    value={data?.state || ''}
                    onChange={handleUpdateEditorData}
                    size='sm'
                    placeholder='Province'
                  />
                </FormControl>
                <FormControl>
                  <FormLabel mb='0' fontSize='sm'>
                    Province
                  </FormLabel>
                  <Input
                    type='text'
                    name='postalCode'
                    value={data?.postalCode || ''}
                    onChange={handleUpdateEditorData}
                    size='sm'
                    placeholder='Postal Code'
                  />
                </FormControl>
              </>
            )}
            <FormControl>
              <Checkbox
                name='enabled'
                isChecked={!!data?.enabled}
                value='true'
                onChange={handleCheckboxClick}
              >
                Location is enabled (uncheck to hide)
              </Checkbox>
            </FormControl>
            <FormControl>
              <Checkbox
                name='useCityInListView'
                isChecked={!!data?.useCityInListView}
                value='true'
                onChange={handleCheckboxClick}
              >
                Use City Name in List View
              </Checkbox>
            </FormControl>
            <FormControl>
              <Checkbox
                name='breadstackManaged'
                isChecked={!!data?.breadstackManaged}
                value='true'
                onChange={handleCheckboxClick}
              >
                Store is managed by Breadstack
              </Checkbox>
            </FormControl>
            {data.dutchieData ? (
              <Text>Original URL: {`/menu/${data.dutchieData.slug}`}</Text>
            ) : (
              <Text>This store is not dutchie-enabled</Text>
            )}
            {!!data?.breadstackManaged && (
              <FormControl>
                <FormLabel mb='0' fontSize='sm'>
                  Breadstack Full Shop URL <br />
                  <span
                    style={{
                      fontSize: '11px',
                      marginBottom: '6px',
                      marginLeft: '12px',
                      display: 'inline-block',
                      borderRadius: '5px',
                      padding: '3px 6px',
                      border: '1px solid #ddd',
                      backgroundColor: '#f9f9f9'
                    }}
                  >
                    no domain just the path. it should look something like:
                    <br />
                    <strong>/shop/?change-location=STORE_SLUG&age=19</strong>
                  </span>
                </FormLabel>
                <Input
                  type='text'
                  name='breadstackShopUrl'
                  value={data?.breadstackShopUrl || ''}
                  onChange={handleUpdateEditorData}
                  size='sm'
                  placeholder='Enter Breadstack Shop URL'
                />
              </FormControl>
            )}
            {/* <FormControl>
              <Checkbox
                name='hideFromMultiLocation'
                isChecked={!!data?.hideFromMultiLocation}
                value='true'
                onChange={handleCheckboxClick}
              >
                Single site/domain shopping only (i.e., delivery)
              </Checkbox>
            </FormControl> */}
            <Flex gap='20px'>
              <FormControl flex='1'>
                <FormLabel mb='0' fontSize='sm'>
                  Phone
                </FormLabel>
                <Input
                  type='text'
                  name='phone'
                  value={data?.phone || ''}
                  onChange={handleUpdateEditorData}
                  size='sm'
                  placeholder='Store phone number'
                />
              </FormControl>
              <FormControl flex='1'>
                <FormLabel mb='0' fontSize='sm'>
                  Google Place ID
                </FormLabel>
                <Input
                  type='text'
                  name='googlePlaceId'
                  value={data?.googlePlaceId || ''}
                  onChange={handleUpdateEditorData}
                  size='sm'
                  placeholder='Google place ID or URL'
                />
              </FormControl>
            </Flex>
            <FormControl mt='12px'>
              <FormLabel mb='0' fontSize='sm'>
                Description
              </FormLabel>
              <Box width={['80vw', '400px', '400px', '480px', '600px']}>
                <RichContentBlock
                  data={data?.description || ''}
                  name='description'
                  onChange={handleRichContentBlockChange}
                />
              </Box>
            </FormControl>
            <Heading as='h2' size='md' mt='10px'>
              Hours of Operation
            </Heading>
            <FormControl>
              <Checkbox
                name='useDefaultHours'
                isChecked={!!data?.useDefaultHours}
                value='true'
                onChange={handleCheckboxClick}
              >
                Use Default Hours
              </Checkbox>
            </FormControl>
            {!data?.useDefaultHours ? (
              <>
                <Heading as='h3' size='xs' mt='10px'>
                  Set hours for this location:
                </Heading>

                <Flex gap='10px'>
                  <Box>
                    <Text fontSize='sm' height='30px' lineHeight='26px'>
                      Monday:
                    </Text>
                    <Text fontSize='sm' height='30px' lineHeight='26px'>
                      Tuesday:
                    </Text>
                    <Text fontSize='sm' height='30px' lineHeight='26px'>
                      Wednesday:
                    </Text>
                    <Text fontSize='sm' height='30px' lineHeight='26px'>
                      Thursday:
                    </Text>
                    <Text fontSize='sm' height='30px' lineHeight='26px'>
                      Friday:
                    </Text>
                    <Text fontSize='sm' height='30px' lineHeight='26px'>
                      Saturday:
                    </Text>
                    <Text fontSize='sm' height='30px' lineHeight='26px'>
                      Sunday:
                    </Text>
                  </Box>
                  <Box>
                    <SetLocationHours
                      baseName='hrs_mon'
                      data={data}
                      handler={handleUpdateEditorData}
                    />
                    <SetLocationHours
                      baseName='hrs_tue'
                      data={data}
                      handler={handleUpdateEditorData}
                    />
                    <SetLocationHours
                      baseName='hrs_wed'
                      data={data}
                      handler={handleUpdateEditorData}
                    />
                    <SetLocationHours
                      baseName='hrs_thu'
                      data={data}
                      handler={handleUpdateEditorData}
                    />
                    <SetLocationHours
                      baseName='hrs_fri'
                      data={data}
                      handler={handleUpdateEditorData}
                    />
                    <SetLocationHours
                      baseName='hrs_sat'
                      data={data}
                      handler={handleUpdateEditorData}
                    />
                    <SetLocationHours
                      baseName='hrs_sun'
                      data={data}
                      handler={handleUpdateEditorData}
                    />
                  </Box>
                </Flex>
                <Box my='12px'>
                  <SetHours locations={locations} setLocationHours={handleSetLocationHours} />
                </Box>
              </>
            ) : (
              <Box>
                <Text fontSize='md'>
                  <Text fontSize='sm' as='em'>
                    This store hours are shared with the Store Default Hours in settings
                  </Text>
                  <br />
                  <Link as={RouterLink} to='/settings' color={PLC_MID_GREEN}>
                    Edit default hours in settings
                  </Link>
                </Text>
              </Box>
            )}
            <Box mt='40px'>
              <ButtonGroup>
                <Button onClick={handleSave} disabled={!changesDetected}>
                  Save
                </Button>
                <SaveCancelDialogue
                  handleSave={handleSave}
                  handleStartCancel={handleStartCancel}
                  handleDiscard={() => navigate(-1)}
                  cancelLabel='Back'
                />
              </ButtonGroup>
            </Box>
          </Box>
        </Flex>
        <Box flex='1'>
          <Heading as='h2' size='md' mt='12px'>
            Location Image:
          </Heading>
          <FileUploadSingleImage
            data={data}
            handleUpdateEditorDataDirect={handleUpdateEditorDataDirect}
            handleFileUpload={handleFileUpload}
            maxPreviewWidth='300px'
            promptLabel='Select location image'
            selectedFileLabel='location image'
            actionLabel='Select image'
          />
          <Box h='1px' w='100%' borderBottom={`1px solid #ddd`} my='24px' />
          <Heading as='h2' size='md'>
            Dutchie Store Info:
          </Heading>
          {data.dutchieData ? (
            <Box fontSize='sm'>
              <Table>
                <Tbody>
                  <Tr>
                    <Td>Name</Td>
                    <Td>{data.dutchieData?.name}</Td>
                  </Tr>
                  <Tr>
                    <Td>Retailer ID</Td>
                    <Td>{data.dutchieId}</Td>
                  </Tr>
                  <Tr>
                    <Td verticalAlign='top'>Address</Td>
                    <Td>
                      {data.dutchieData?.addressObject?.line1}
                      <br />
                      {data.dutchieData?.addressObject?.city} &nbsp;
                      {data.dutchieData?.addressObject?.postalCode}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Store Slug</Td>
                    <Td>
                      <Link
                        href={`http://localhost:3000/menu/${data.dutchieData?.slug}`}
                        target='_new'
                      >
                        {data.dutchieData?.slug}
                      </Link>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td verticalAlign='top'>Map Coordinates</Td>
                    <Td>
                      Lat: {data.dutchieData?.coordinates.latitude}
                      <br />
                      Long: {data.dutchieData?.coordinates.longitude}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          ) : (
            <Text fontSize='sm' as='em'>
              Breadstack-only stores don't have "Dutchie Data", that is ok.
            </Text>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default Editor;
